import React from 'react';
import { DateTime } from 'luxon';
import { unparse } from 'papaparse';
import { Box, Button, Heading } from 'rebass';

const DataTable = ({ data, isInverted, nodeId, startDate, endDate }) => {
  if (!data.length) return (<div><h3>No data found for that date.</h3></div>);

  const tableData = isInverted
    ? data.map(datum => ({
      ...datum,
      meanHeatRatio: 1 / datum.meanHeatRatio,
      nodeId
    }))
    : data.map(datum => ({ ...datum, nodeId }));
  const csvData = unparse(tableData);
  const blob = new Blob([csvData], { type: 'text/plain;charset=utf-8' });
  const downloadUrl = URL.createObjectURL(blob);

  return (
    <div style={{ overflowX: 'scroll' }}>
      <Box>
        <Heading mt='1.45rem'>Raw Data</Heading>
        <a href={downloadUrl} download={`${nodeId}_${startDate}_to_${endDate}.csv`}>
          <Button
            mt={2}
            variant='secondary'
            className='update-button'>
            Export to CSV
            </Button>
        </a>
      </Box>
      <table style={{ margin: 'auto', whiteSpace: 'nowrap' }}>
        <thead>
          <tr>
            {Object.keys(tableData[0]).map((hrHeader, i) => <th key={`header-${i}`}>{hrHeader}</th>)}
          </tr>
        </thead>
        <tbody>
          {
            tableData.map(
              (row, i) =>
                <tr key={`row-${i}`}>
                  {
                    Object.entries(row).map(
                      ([columnName, value], j) =>
                        <td key={`value-${j}`}>
                          {
                            columnName === 'timestamp'
                              ? DateTime.fromSeconds(+value).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
                              : value
                          }
                        </td>
                    )
                  }
                </tr>
            )
          }
        </tbody>
      </table>
    </div>)
};

export default DataTable;
