import React from 'react';
import { DateTime, Duration } from 'luxon';
import NodeHistories from './NodeHistories';
import { Heading } from 'rebass';

const NodeStatus = ({
  nodes,
  loading,
  error,
  dailyCounts,
  loadingDailyCounts,
  fetchDailyCountsError
}) => {
  if (error && error.length) {
    console.log('error', error);
    return (<div><h3>Error</h3></div>)
  };

  if (loading || !nodes) return (<div><h3>Loading node status...</h3></div>);

  let max = 1;

  if (dailyCounts) {
    const data = JSON.parse(dailyCounts.nodes)
      .flatMap(record =>
        Object.entries(record)
          .flatMap(([k, v]) => k === 'pk1' || k === 'sk1' ? [] : [v])
      );

    max = Math.max(max, ...data);
  }

  return (
    <>
      <Heading mt='1.45rem'>Status</Heading>
      <div style={{ overflowX: 'scroll' }}>
        <table style={{ margin: 'auto', whiteSpace: 'nowrap' }}>
          <thead>
            <tr>
              <th>Node ID</th>
              <th>Message History</th>
              <th>Last Connected Timestamp</th>
              <th>Last Sent Data Timestamp</th>
              <th>Last Sent Data</th>
              <th>Heat Pulse Duration</th>
              <th>Sleep Time</th>
              <th>SD Card Initialized</th>
            </tr>
          </thead>
          <tbody>
            {
              nodes.map((node, i) =>
                <tr key={`node-${i}`}>
                  <td>{node.nodeId}</td>
                  <td>
                    <NodeHistories
                      nodeId={node.nodeId}
                      nodes={nodes}
                      data={dailyCounts}
                      loading={loadingDailyCounts}
                      error={fetchDailyCountsError}
                      max={max}
                    />
                  </td>
                  <td>
                    {
                      !Number.isNaN(node.lastReceivedShadowTimestamp)
                        ? DateTime.fromISO(node.lastReceivedShadowTimestamp)
                          .toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)
                        : ''
                    }
                  </td>
                  <td>
                    {
                      !Number.isNaN(node.lastHeardTimestamp)
                        ? DateTime.fromISO(node.lastHeardTimestamp)
                          .toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)
                        : ''
                    }
                  </td>
                  <td>
                    Mean Heat Ratio: {
                      node.lastHeardValue
                        ? JSON.parse(node.lastHeardValue).meanHeatRatio
                        : ''
                    } Temp 1: {
                      !node.lastHeardValue
                        ? ''
                        : JSON.parse(
                          JSON.parse(node.lastHeardValue).heatPulseDataUsed
                        )[0]
                          ? JSON.parse(
                            JSON.parse(node.lastHeardValue).heatPulseDataUsed
                          )[0].temp1
                          : 'N/A'
                    } Temp 2: {
                      !node.lastHeardValue
                        ? ''
                        : JSON.parse(
                          JSON.parse(node.lastHeardValue).heatPulseDataUsed
                        )[0]
                          ? JSON.parse(
                            JSON.parse(node.lastHeardValue).heatPulseDataUsed
                          )[0].temp2
                          : 'N/A'
                    }
                  </td>
                  <td>
                    {
                      node.lastReceivedShadow && JSON.parse(node.lastReceivedShadow).heatPulseDuration
                        ? `${Duration.fromMillis(JSON.parse(node.lastReceivedShadow).heatPulseDuration).as('seconds')} seconds`
                        : ''
                    }
                  </td>
                  <td>
                    {
                      node.lastReceivedShadow && JSON.parse(node.lastReceivedShadow).sleepTime
                        ? `${Duration.fromMillis(JSON.parse(node.lastReceivedShadow).sleepTime * 1000).as('minutes')} minutes`
                        : ''
                    }
                  </td>
                  <td>
                    {
                      !node.lastReceivedShadow
                        ? ''
                        : JSON.parse(node.lastReceivedShadow).sdCardInitialized
                          ? 'Yes'
                          : 'No'
                    }
                  </td>
                </tr>
              )
            }
          </tbody>
        </table>
      </div>
    </>
  )
};

export default NodeStatus;