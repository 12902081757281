/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMeanHeatRatiosForDate = /* GraphQL */ `
  query GetMeanHeatRatiosForDate($nodeId: String!, $date: String!) {
    getMeanHeatRatiosForDate(nodeId: $nodeId, date: $date) {
      date
      meanHeatRatio
      meanSoilMoisture
      meanOutsideTemp
      meanOutsideHumidity
      heatPulseDataUsed
      identifier
      timestamp
    }
  }
`;
export const getMeanHeatRatiosBetweenTimes = /* GraphQL */ `
  query GetMeanHeatRatiosBetweenTimes(
    $nodeId: String!
    $startTime: String!
    $endTime: String!
  ) {
    getMeanHeatRatiosBetweenTimes(
      nodeId: $nodeId
      startTime: $startTime
      endTime: $endTime
    ) {
      date
      meanHeatRatio
      meanSoilMoisture
      meanOutsideTemp
      meanOutsideHumidity
      heatPulseDataUsed
      identifier
      timestamp
    }
  }
`;
export const getMeanHeatRatiosBetweenTimesPaginated = /* GraphQL */ `
  query GetMeanHeatRatiosBetweenTimesPaginated(
    $nodeId: String!
    $startTime: String!
    $endTime: String!
    $nextToken: String
  ) {
    getMeanHeatRatiosBetweenTimesPaginated(
      nodeId: $nodeId
      startTime: $startTime
      endTime: $endTime
      nextToken: $nextToken
    ) {
      items {
        date
        meanHeatRatio
        meanSoilMoisture
        meanOutsideTemp
        meanOutsideHumidity
        heatPulseDataUsed
        identifier
        timestamp
      }
      nextToken
    }
  }
`;
export const getCustomerName = /* GraphQL */ `
  query GetCustomerName {
    getCustomerName {
      customerName
    }
  }
`;
export const getNodes = /* GraphQL */ `
  query GetNodes {
    getNodes {
      nodeId
      lastHeardTimestamp
      lastHeardValue
      lastReceivedShadow
      lastReceivedShadowTimestamp
    }
  }
`;
export const getDailyCounts = /* GraphQL */ `
  query GetDailyCounts($startDate: String!, $endDate: String!) {
    getDailyCounts(startDate: $startDate, endDate: $endDate) {
      nodes
    }
  }
`;
export const getThingsShadowInfoBetweenTimesPaginated = /* GraphQL */ `
  query GetThingsShadowInfoBetweenTimesPaginated(
    $nodeId: String!
    $startTime: String!
    $endTime: String!
    $nextToken: String
  ) {
    getThingsShadowInfoBetweenTimesPaginated(
      nodeId: $nodeId
      startTime: $startTime
      endTime: $endTime
      nextToken: $nextToken
    ) {
      items {
        nodeId
        lastReceivedShadow
        lastReceivedShadowTimestamp
      }
      nextToken
    }
  }
`;
export const getOnDeviceMeanHeatRatiosBetweenTimesPaginated = /* GraphQL */ `
  query GetOnDeviceMeanHeatRatiosBetweenTimesPaginated(
    $nodeId: String!
    $startTime: String!
    $endTime: String!
    $nextToken: String
  ) {
    getOnDeviceMeanHeatRatiosBetweenTimesPaginated(
      nodeId: $nodeId
      startTime: $startTime
      endTime: $endTime
      nextToken: $nextToken
    ) {
      items {
        meanHeatRatio
        timestamp
      }
      nextToken
    }
  }
`;
