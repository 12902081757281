import React from 'react';
import { VegaLite } from 'react-vega';
import { Handler } from 'vega-tooltip';
import { useWindowWidth } from '@react-hook/window-size';
import { DateTime } from 'luxon';

const HeatRatioChart = ({ data, isInverted, upperBound, lowerBound, chartType, thingShadows }) => {
  const width = useWindowWidth();
  let dataWithDates = data
    .map(datum => (
      isInverted
        ? {
          ...datum,
          dateTime: DateTime.fromSeconds(+datum.timestamp),
          meanHeatRatio: 1 / datum.meanHeatRatio
        }
        : {
          ...datum,
          dateTime: DateTime.fromSeconds(+datum.timestamp)
        }
    ));

  if (upperBound !== null && upperBound !== '') {
    dataWithDates = dataWithDates.filter(datum => +datum.meanHeatRatio < +upperBound);
  }

  if (lowerBound !== null && lowerBound !== '') {
    dataWithDates = dataWithDates.filter(datum => +datum.meanHeatRatio > +lowerBound);
  }

  const thingShadowsData = thingShadows.map(datum => ({
    ...datum,
    dateTime: DateTime.fromISO(datum.lastReceivedShadowTimestamp)
  }));

  const spec = {
    vconcat: [
      {
        width: Math.min(900, width * 0.7),
        mark: { type: chartType },
        encoding: {
          x: { field: 'dateTime', type: 'temporal', format: '%b %d, %Y %H:%M:%S', title: 'Time' },
          y: { field: 'meanHeatRatio', type: 'quantitative', scale: { zero: false }, title: 'Mean Heat Ratios' },
          color: {
            condition: {
              test: "datum.source === 'Calculated by Datalogger'",
              value: 'green'
            }
          },
          tooltip: [
            { field: 'dateTime', type: 'temporal', format: '%b %d, %Y %H:%M:%S', title: 'Time' },
            { field: 'meanHeatRatio', type: 'quantitative', title: 'Mean Heat Ratios' },
            { field: 'source', type: 'nominal', title: 'Source' }
          ]
        },
        height: 500,
        data: { name: 'table' }
      },
      {
        width: Math.min(900, width * 0.7),
        mark: { type: chartType, tooltip: true },
        encoding: {
          x: { field: 'dateTime', type: 'temporal', format: '%b %d, %Y %H:%M:%S', title: 'Time' },
          y: { field: 'meanHeatRatio', type: 'quantitative', scale: { zero: false }, title: 'Mean Heat Ratios' },
          color: {
            condition: {
              test: "datum.source === 'Calculated by Datalogger'",
              value: 'green'
            }
          }
        },
        height: 60,
        data: { name: 'table' }
      },
      {
        width: Math.min(900, width * 0.7),
        height: 60,
        mark: { type: 'point', tooltip: true },
        encoding: {
          x: { field: 'dateTime', type: 'temporal', format: '%b %d, %Y %H:%M:%S', title: 'Time' }
        },
        data: { name: 'thingShadowsData' },
        title: 'Datalogger Connections to Server'
      }
    ]
  };

  const chartData = {
    table: dataWithDates,
    thingShadowsData
  };

  return <VegaLite spec={spec} data={chartData} tooltip={new Handler().call} />
};

export default HeatRatioChart;