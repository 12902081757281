import React, { useState } from 'react';
import DataTable from './DataTable';
import { DateTime } from 'luxon';
import HeatRatioChart from './HeatRatioChart';
import { pick } from 'ramda';
import { Box, Flex } from 'rebass';
import { Label, Radio, Checkbox } from '@rebass/forms';

const Dashboard = ({
  data,
  loading,
  error,
  upperBound,
  lowerBound,
  nodeId,
  startDate,
  endDate,
  thingShadows,
  onDeviceHeatRatios,
}) => {
  const [isInverted, setIsInverted] = useState(false);
  const [chartType, setChartType] = useState('point');

  if (error) {
    console.log('error', error);
    return (
      <div>
        <h3>Error loading data</h3>
      </div>
    );
  }

  if (loading)
    return (
      <div>
        <h3>Loading...</h3>
      </div>
    );

  if (!data || !thingShadows) return null;

  if (!data.length)
    return (
      <div>
        <h3>No data found for that date.</h3>
      </div>
    );

  data.sort(
    (a, b) =>
      DateTime.fromSeconds(+a.timestamp) - DateTime.fromSeconds(+b.timestamp)
  );

  const dataForTable = data.map((datum) =>
    pick(
      [
        'date',
        'meanHeatRatio',
        'meanSoilMoisture',
        'meanOutsideTemp',
        'meanOutsideHumidity',
        'timestamp',
      ],
      datum
    )
  );

  if (onDeviceHeatRatios) {
    onDeviceHeatRatios = onDeviceHeatRatios.map((datum) => ({
      ...datum,
      source: 'Calculated by Datalogger',
    }));
    data = data.map((datum) => ({ ...datum, source: 'Calculated by Server' }));
    data = [...data, ...onDeviceHeatRatios];
  }

  return (
    <div>
      <Flex justifyContent="center">
        <Box>
          <Label alignItems="center">
            <Checkbox
              checked={isInverted}
              onChange={() => setIsInverted(!isInverted)}
            />
            Invert Heat Ratios
          </Label>
        </Box>
      </Flex>
      <section className="container">
        <Box>
          <h2>Mean Heat Ratios</h2>
          <HeatRatioChart
            data={data}
            isInverted={isInverted}
            upperBound={upperBound}
            lowerBound={lowerBound}
            chartType={chartType}
            thingShadows={thingShadows}
          />
          <Box>
            <Label>
              <Radio
                name="chartType"
                id="scatter"
                value="point"
                checked={chartType === 'point'}
                onChange={(e) => setChartType(e.target.value)}
              />
              Scatter Plot
            </Label>
            <Label>
              <Radio
                name="chartType"
                id="line"
                value="line"
                checked={chartType === 'line'}
                onChange={(e) => setChartType(e.target.value)}
              />
              Line Plot
            </Label>
          </Box>
          <div style={{ width: '100%' }}>
            <div
              style={{ textAlign: 'left', maxWidth: '650px', margin: 'auto' }}
            >
              <p>
                Connections to the server without a corresponding mean heat
                ratio indicate that either:
              </p>
              <ol>
                <li>
                  a mean heat ratio could not be calculated for that heat pulse
                  due to missing/invalid key data points, (e.g., temperature
                  readings of -40 C); or
                </li>
                <li>
                  the datalogger failed to sent the payload to the server.
                </li>
              </ol>
            </div>
          </div>
        </Box>
      </section>
      <section className="container">
        <DataTable
          data={dataForTable}
          isInverted={isInverted}
          nodeId={nodeId}
          startDate={startDate}
          endDate={endDate}
        />
      </section>
    </div>
  );
};

export default Dashboard;
