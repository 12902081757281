// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://33due5qoengw7lbog5jg7lwzoe.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-gcgp7qx4ungothbx3pvl3uffqi",
    "aws_cognito_identity_pool_id": "us-west-2:4908bdda-ea1d-4741-8c1d-c07ac5d414df",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_EPspRf8Kq",
    "aws_user_pools_web_client_id": "445e855797oiuk774r7ee4lgk9",
    "oauth": {}
};


export default awsmobile;
