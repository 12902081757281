import React, { useState } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import awsconfig from './aws-exports';
import Dashboard from './components/Dashboard';
import NodeStatus from './components/NodeStatus';
import './App.css';
import { DateTime } from 'luxon';
import useFetchedHeatRatios from './hooks/useFetchedHeatRatios';
import useFetchedNodes from './hooks/useFetchedNodes';
import useFetchedDailyCounts from './hooks/useFetchedDailyCounts';
import useFetchedThingShadows from './hooks/useFetchedThingShadows';
import useFetchedOnDeviceHeatRatios from './hooks/useFetchedOnDeviceHeatRatios';
import { Button, Box, Flex, Heading } from 'rebass';
import { Input, Label, Select } from '@rebass/forms';

Amplify.configure({
  ...awsconfig,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  // "aws_user_pools_id": 'us-west-2_ciEKNF1d0',
  // "aws_user_pools_web_client_id": '1ikm6vp89jsscs3c60n3ej12u4',
  graphql_headers: async () => {
    const currentSession = await Auth.currentSession();
    return { Authorization: currentSession.getIdToken().getJwtToken() };
  },
});

const START_DATE = DateTime.local().toISODate();
const END_DATE = DateTime.local().toISODate();

const App = () => {
  const defaults = {
    startDate: START_DATE,
    startTimestamp: `${DateTime.fromISO(START_DATE).toSeconds()}`,
    endDate: END_DATE,
    endTimestamp: `${DateTime.fromISO(END_DATE)
      .set({ hour: 23, minute: 59, second: 59 })
      .toSeconds()}`,
    nodeId: 'select',
    upperBound: Infinity,
    lowerBound: -Infinity,
  };
  const [startDate, setStartDate] = useState(defaults.startDate);
  const [startTimestamp, setStartTimestamp] = useState(
    `${DateTime.fromISO(defaults.startDate).toSeconds()}`
  );
  const [endDate, setEndDate] = useState(defaults.endDate);
  const [endTimestamp, setEndTimestamp] = useState(
    `${DateTime.fromISO(defaults.endDate)
      .set({ hour: 23, minute: 59, second: 59 })
      .toSeconds()}`
  );
  const [nodeId, setNodeId] = useState(defaults.nodeId);
  const [upperBound, setUpperBound] = useState(defaults.upperBound);
  const [lowerBound, setLowerBound] = useState(defaults.lowerBound);
  const [dashboardData, setDashboardData] = useState(defaults);
  const { fetchedData, loading, error } = useFetchedHeatRatios(dashboardData);
  const {
    fetchedData: onDeviceHeatRatios,
    loading: loadingOnDeviceHeatRatios,
    error: fetchOnDeviceHeatRatiosError,
  } = useFetchedOnDeviceHeatRatios(dashboardData);
  const {
    fetchedData: nodes,
    loading: loadingNodes,
    error: fetchNodesError,
  } = useFetchedNodes();
  const {
    fetchedData: dailyCounts,
    loading: loadingDailyCounts,
    error: fetchDailyCountsError,
  } = useFetchedDailyCounts();
  const {
    fetchedData: thingShadows,
    loading: loadingThingShadows,
    error: fetchThingShadowsError,
  } = useFetchedThingShadows(dashboardData);

  return (
    <div className="App">
      <section className="container">
        <Heading fontSize={6}>Dotmote Labs</Heading>
        <Flex justifyContent="center">
          <Box maxWidth="290px">
            <Box mb={2}>
              <Label htmlFor="start-date-picker" fontSize={1} fontWeight="bold">
                Pick a start date:
              </Label>
              <Input
                id="start-date-picker"
                value={startDate}
                type="date"
                onChange={(e) => {
                  setStartDate(e.target.value);
                  setStartTimestamp(
                    DateTime.fromISO(e.target.value).toSeconds()
                  );
                }}
              />
            </Box>
            <Box mb={2}>
              <Label htmlFor="end-date-picker" fontSize={1} fontWeight="bold">
                Pick an end date:
              </Label>
              <Input
                id="end-date-picker"
                value={endDate}
                type="date"
                onChange={(e) => {
                  setEndDate(e.target.value);
                  setEndTimestamp(
                    DateTime.fromISO(e.target.value)
                      .set({ hour: 23, minute: 59, second: 59 })
                      .toSeconds()
                  );
                }}
              />
            </Box>
            <Box mb={2}>
              {nodes ? (
                <>
                  <Label htmlFor="node-picker" fontSize={1} fontWeight="bold">
                    Pick a Node ID:
                  </Label>
                  <Select
                    id="node-picker"
                    value={nodeId}
                    onChange={(e) => {
                      setNodeId(e.target.value);
                    }}
                  >
                    <option value="select">Select a Node</option>
                    {nodes.map((node, i) => (
                      <option key={`node-${i}`}>{node.nodeId}</option>
                    ))}
                  </Select>
                </>
              ) : null}
            </Box>
            <Box mb={2}>
              <Label htmlFor="upper-bound" fontSize={1} fontWeight="bold">
                Hide mean heat ratios greater than:
              </Label>
              <Input
                id="upper-bound"
                type="number"
                value={upperBound !== -Infinity ? upperBound : null}
                onChange={(e) => {
                  setUpperBound(e.target.value);
                }}
                step="0.01"
              />
            </Box>
            <Box mb={2}>
              <Label htmlFor="upper-bound" fontSize={1} fontWeight="bold">
                Hide mean heat ratios less than:
              </Label>
              <Input
                id="lower-bound"
                type="number"
                value={lowerBound !== Infinity ? lowerBound : null}
                onChange={(e) => {
                  setLowerBound(e.target.value);
                }}
                step="0.01"
              />
            </Box>
            <Button
              mb={2}
              variant="primary"
              className="update-button"
              onClick={(e) => {
                setDashboardData({
                  startDate,
                  endDate,
                  startTimestamp,
                  endTimestamp,
                  nodeId,
                  upperBound,
                  lowerBound,
                });
              }}
            >
              Update
            </Button>
          </Box>
        </Flex>
      </section>
      <Dashboard
        data={fetchedData}
        thingShadows={thingShadows}
        loading={loading}
        loadingThingShadows={loadingThingShadows}
        error={error}
        fetchThingShadowsError={fetchThingShadowsError}
        onDeviceHeatRatios={onDeviceHeatRatios}
        {...dashboardData}
      />
      <NodeStatus
        nodes={nodes}
        loading={loadingNodes}
        error={fetchNodesError}
        dailyCounts={dailyCounts}
        loadingDailyCounts={loadingDailyCounts}
        fetchDailyCountsError={fetchDailyCountsError}
      />
      <section className="signout-container">
        <AmplifySignOut />
      </section>
    </div>
  );
};

export default withAuthenticator(App);
