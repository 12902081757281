import { useEffect, useState } from 'react';
import fetchItemsNextToken from '../utils/fetchItemsNextToken';
import * as queries from '../graphql/queries';

function useFetchedOnDeviceHeatRatios(dashboardData) {
  const [error, setError] = useState(null);
  const [fetchedData, setFetchedData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const queryName = 'getOnDeviceMeanHeatRatiosBetweenTimesPaginated';

      try {
        setLoading(true);
        const data = await fetchItemsNextToken({
          query: queries[queryName],
          variables: {
            nodeId: dashboardData.nodeId,
            startTime: dashboardData.startTimestamp,
            endTime: dashboardData.endTimestamp
          },
          queryName
        });

        setFetchedData(data);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setError(e);
      }
    }

    if (dashboardData.nodeId && dashboardData.nodeId !== 'select') {
      fetchData();
    }

  }, [dashboardData.endTimestamp, dashboardData.nodeId, dashboardData.startTimestamp]);

  return {
    error,
    fetchedData,
    loading
  };
}

export default useFetchedOnDeviceHeatRatios;