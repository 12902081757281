import React from 'react';
import { VegaLite } from 'react-vega';
import { Handler } from 'vega-tooltip';
import { DateTime } from 'luxon';

const NodeHistories = ({ data, error, loading, nodes, nodeId, max }) => {
  if (error && error.length) {
    console.log('error', error);
    return (
      <div>
        <h3>Error</h3>
      </div>
    );
  }

  if (loading || !data || !nodes) {
    return (
      <div>
        <h3>Loading node histories...</h3>
      </div>
    );
  }

  // generate array of data points into shape Vega expects
  const dataForChart = JSON.parse(data.nodes)
    .map((datum) =>
      Object.entries(datum).flatMap(([k, v]) =>
        k === 'pk1' || k === 'sk1'
          ? []
          : {
              nodeId: k,
              count: v,
              date: datum.sk1,
            }
      )
    )
    .flat()
    .filter((datum) => datum.nodeId === nodeId);

  // generate data for past week, imputing missing values
  const currentTime = DateTime.utc();
  const pastWeekUTC = Array.from(Array(7)).map((_, i) => ({
    date: currentTime.startOf('day').minus({ days: i }).toISO(),
    count: 0,
  }));

  const pastWeek = pastWeekUTC.map((day) => {
    const correspondingData = dataForChart.find(
      (datum) => datum.date === day.date
    );

    return correspondingData
      ? {
          ...day,
          date: DateTime.fromISO(day.date, { zone: 'utc' }).toFormat(
            'LL/dd/yyyy'
          ),
          count: correspondingData.count,
        }
      : {
          ...day,
          date: DateTime.fromISO(day.date, { zone: 'utc' }).toFormat(
            'LL/dd/yyyy'
          ),
        };
  });

  const spec = {
    mark: { type: 'rect', tooltip: true, strokeWidth: 1 },
    encoding: {
      x: {
        field: 'date',
        type: 'ordinal',
        title: 'Date (UTC)',
        axis: null,
        timeUnit: 'yearmonthdate',
      },
      color: {
        field: 'count',
        type: 'quantitative',
        legend: null,
        condition: {
          test: "datum['count'] === 0",
          value: '#eeeeee',
        },
        title: 'Message Count',
        scale: {
          domain: [1, max],
          scheme: 'yellowgreen',
        },
      },
      stroke: {
        value: 'black',
      },
    },
    data: { name: 'table' }, // note: vega-lite data attribute is a plain object instead of an array
  };
  const chartData = {
    table: pastWeek,
  };

  return (
    <VegaLite
      spec={spec}
      data={chartData}
      tooltip={new Handler().call}
      actions={false}
    />
  );
};

export default NodeHistories;
