import { useEffect, useState } from 'react';
import * as queries from '../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import { DateTime } from 'luxon';

function useFetchedDailyCounts() {
  const [error, setError] = useState(null);
  const [fetchedData, setFetchedData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const queryName = 'getDailyCounts';

      try {
        setLoading(true);
        const today = DateTime.utc().startOf('day');
        const { data } = await API.graphql(
          graphqlOperation(
            queries[queryName],
            {
              startDate: today.minus({ days: 7 }).toISO(),
              endDate: today.plus({ days: 1 }).toISO()
            }
          )
        );
        setFetchedData(data[queryName]);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setError(e);
      }
    }

    fetchData();

  }, []);

  return {
    error,
    fetchedData,
    loading
  };
}

export default useFetchedDailyCounts;